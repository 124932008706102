import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */

/* @jsx mdx */

export const _frontmatter = {
  "title": "Ihr Karriereportal",
  "description": "Hier finden Sie alle Module die auf der Karriereseite aktiviert werden können",
  "author": "Jochen Ritscher",
  "categories": ["settings"],
  "date": "2021-07-30T00:00:00.000Z",
  "featured": false
};
const layoutProps = {
  _frontmatter
};
const MDXLayout = "wrapper";
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">
    <p>{`Hier finden Sie alle Module die auf der Karriereseite aktiviert werden können`}</p>
    <h2>{`Navigationsleiste von ihrer Website`}</h2>
    <p>{`Die Navigation Ihrer Website kann in das Karriereportal übernommen werden`}</p>
    <h2>{`Firmenlogo & Favicon`}</h2>
    <p>{`Ihr Firmenlogo kann im Header und als Favicon angezeigt werden`}</p>
    <h2>{`Sprachumschalter`}</h2>
    <p>{`Standardmäßig wird ein Sprachumschaltelement für die hinterlegten Sprachvarianten angezeigt. `}</p>
    <img src="/images/settings/ihrportal-lang.jpg" alt="Sprachumschalter" style={{
      "width": "100%"
    }} />
    <h2>{`Textbausteine für den Willkommenstext`}</h2>
    <img src="/images/settings/ihrportal-header.jpg" alt="Willkommenstext" style={{
      "width": "100%"
    }} />
    <p>{`Stellenanzeigenliste`}</p>
    <img src="/images/settings/ihrportal-list.jpg" alt="Willkommenstext" style={{
      "width": "100%"
    }} />
    <p>{`Kartenansicht mit Filtermöglichkeit nach Standorten oder Einsatzorten`}</p>
    <img src="/images/settings/ihrportal-map.jpg" alt="Kartenansicht" style={{
      "width": "100%"
    }} />
    <h2>{`Arbeitgeber-Benefits`}</h2>
    <p>{`Beispiel:`}</p>
    <img src="/images/settings/ihrportal-benefits.jpg" alt="Arbeitgeber-Benefits" style={{
      "width": "100%"
    }} />
    <h2>{`Vorstellung Ihrer Standorte`}</h2>
    <p>{`Beispiel:`}</p>
    <img src="/images/settings/ihrportal-sites.jpg" alt="Standorte" style={{
      "width": "100%"
    }} />
    <h2>{`Impressionen aus Ihrem Unternehmen (Foto-Slider) & Philosophie`}</h2>
    <img src="/images/settings/ihrportal-impressionen.jpg" alt="Impressionen" style={{
      "width": "100%"
    }} />
    <h2>{`Link zur Firmenwebsite`}</h2>
    <p>{`Verlinken Sie zu Ihrer Website`}</p>
    <h2>{`Links zu Social Media Kanälen`}</h2>
    <p>{`Verknüpfungen zu Ihren Employer Branding Kanälen auf Facebook, Twitter, XING, LinkedIn, YouTube und Vimeo`}</p>
    <h2>{`Einbindung Ihrer Social Media Kanäle`}</h2>
    <p>{`Direkte Einbindung der Inhalte Ihrer Employer Branding Kanäle auf Facebook, Twitter und YouTube`}</p>
    <h2>{`Social Share Leiste`}</h2>
    <p>{`Geben Sie den Karriereportal-Besuchern die Möglichkeit dieses an Kontakte zu teilen`}</p>
    <img src="/images/settings/ihrportal-social.jpg" alt="Impressionen" style={{
      "width": "100%"
    }} />
    <h2>{`RSS-Feed`}</h2>
    <p>{`Bieten Sie Ihren Besuchern die Möglichkeit alle aktuellen Stellenausschreibungen per RSS-Feed abonnieren zu können`}</p>
    <img src="/images/settings/ihrportal-rss.jpg" alt="RSS" style={{
      "width": "100%"
    }} />
    <h2>{`Einbindung eines Employer Branding Videos`}</h2>
    <p>{`Im Videobereich können Sie ein beliebiges Employer Brand Video einbinden. Als Quellen können hier beispielsweise Youtube oder Vimeo, sowie Ihr eigener Videoservice dienen. Es wird lediglich ein Einbettungscode benötigt. Beispiel YouTube: `}<a parentName="p" {...{
        "href": "https://support.google.com/youtube/answer/171780?hl=de"
      }}>{`https://support.google.com/youtube/answer/171780?hl=de`}</a></p>
    <h2>{`Teamvorstellung`}</h2>
    <p>{`Ihr(e) Team(s) (mit wem wird der Bewerber zukünftig zusammenarbeiten)`}</p>
    <h2>{`Ausbildungsberufe und Praktika`}</h2>
    <h2>{`Zertifizierungen`}</h2>
    <p>{`Darstellung von Arbeitgeberzertifizierungen`}</p>
    <img src="/images/settings/ihrportal-cert.jpg" alt="Zertifizierungen" style={{
      "width": "100%"
    }} />
 
## Fußbereich
Der inriva-Fußbereich bietet die Möglichkeit alle relevanten Informationen inklusive Impressum und Datenschutzbedingungen entsprechend Ihrer Website anzuzeigen. Es ist darüber hinaus möglich das Original-Fußbereich Ihrer Website abzurufen und darzustellen.
    <h2>{`AddOns`}</h2>
    <p>{`Mitarbeiterempfehlungen`}</p>
    <h2>{`Verlinkung zu Bewertungsportalen wie Kununu und Glassdoor`}</h2>
    <h2>{`Individuelle Module`}</h2>
    <p>{`Hier fehlen Inhalte die sie benötigen? Sprechen Sie uns an: `}<a parentName="p" {...{
        "href": "mailto:mail@inriva.com"
      }}>{`mail@inriva.com`}</a></p>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      